import { IconButton, TableCell, TableRow } from "@mui/material";
import styled from "styled-components";
import { CustomButton } from "../../globalStyle";

export const CustomRowCell = styled(TableCell)<{ price?: boolean }>`
  display: flex !important;
  width: 300px;
  height: 20px;
  padding: 6px 15px;
  flex-direction: column !important;
  justify-content: start !important;
  align-items: ${(props) => (props.price ? "end" : "flex-start")};
  font-size: 16px !important;
  font-weight: 600 !important;
  gap: 6px;
  border: 1px solid #eaecee;
`;

export const CustomTableRow = styled(TableRow)`
  display: flex !important;
  align-items: center;
  align-self: stretch;
`;

export const CustomTableCell = styled(TableCell)`
  display: flex;
  width: 300px;
  height: 190px;
  padding: 20px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  border: 1px solid #eaecee;
`;

export const IconWrapper = styled.img`
  cursor: pointer;
`;

export const PaymentButton = styled(CustomButton)`
  && {
    font-size: 1rem;
    line-height: 1.4375em;
    color: black;
    display: flex;
    gap: 20px;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.colors.border1};
    border-radius: 30px;
    padding: 14px 40px;
    height: 58px;
  }
`;

export const CustomContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PlanHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 156px;
  padding: 30px;
  border: 1px solid ${({ theme }) => theme.colors.border1};
  border-radius: 15px 15px 0 0;
  background-color: ${({ theme }) => theme.colors.background};
`;
export const PlanDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-top: none !important;
  border: 1px solid ${({ theme }) => theme.colors.border1};
  border-radius: 0 0 15px 15px;
`;
