import SelectDropdown from "../../selectDropdown";
import { StarIcon, StarRatingIcon } from "../../../assets/Icons";
import { Chip, SelectChangeEvent } from "@mui/material";
import { StyledMenuItem } from "../../../globalStyle";
import { useDispatch, useSelector } from "react-redux";
import { MetaDataSagaActions } from "../../../store/metadata/sagas";
import { isLoadingSelector } from "../../../store/metadata/selector";

const RatingsDropdown = ({
  adId,
  ratingValue,
}: {
  adId: string;
  ratingValue?: number;
}) => {
  const dispatch = useDispatch();
  const metadataLoadingState = useSelector(isLoadingSelector);

  const handleRatingChange = (event: SelectChangeEvent) => {
    dispatch(
      MetaDataSagaActions.rateAdById({
        adId,
        rating: event.target.value,
      })
    );
  };

  return (
    <SelectDropdown
      startAdornmentIcon={<StarIcon />}
      renderValue={(selected: number) => (
        <Chip
          label={Array.from({ length: selected }, (_, index) => (
            <StarRatingIcon key={index} />
          ))}
          sx={{ ".MuiChip-label": { display: "flex" } }}
        />
      )}
      handleChange={handleRatingChange}
      value={ratingValue}
      loadingState={metadataLoadingState}
    >
      {[5, 4, 3, 2, 1].map((rating) => (
        <StyledMenuItem key={rating} value={rating}>
          {Array.from({ length: rating }, (_, index) => (
            <StarRatingIcon key={index} />
          ))}
        </StyledMenuItem>
      ))}
    </SelectDropdown>
  );
};

export default RatingsDropdown;
