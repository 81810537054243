import { Grid } from "@mui/material";
import BrandFolderCard from "../commonComponents/brandCards/brandFolderCard";
import ViewAllCard from "../commonComponents/brandCards/viewAllCard";
import FavouriteBrandContainer from "./favouriteBrandContainer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import { favouriteFolderListSelector } from "../../store/metadata/selector";
import { folderItem } from "../../store/metadata/types";

const FavouriteBrandSwipeFile = () => {
  const width600 = useMediaQuery("(min-width:600px)");
  const width991 = useMediaQuery("(min-width:990px)");
  const width1320 = useMediaQuery("(min-width:1320px)");
  const width1500 = useMediaQuery("(min-width:1500px)");
  const width1900 = useMediaQuery("(min-width:1900px)");

  const folderLists = useSelector(favouriteFolderListSelector);

  const getCardCount = () => {
    switch (true) {
      case width1900:
        return folderLists?.slice(0, 7);
      case width1500:
        return folderLists?.slice(0, 6);
      case width1320:
        return folderLists?.slice(0, 5);
      case width991:
        return folderLists?.slice(0, 4);
      case width600:
        return folderLists?.slice(0, 3);
      default:
        return folderLists?.slice(0, 2);
    }
  };
  const folderItems = getCardCount();
  return (
    <FavouriteBrandContainer headerName={"Favourite Folders"}>
      <Grid container spacing={2} sx={{ width: "fit-content" }}>
        {folderItems?.map((item: folderItem, index: number) => (
          <Grid item xs key={index} display="flex">
            <BrandFolderCard key={index} item={item} cardWidth="110px" ableToFavourite={true}/>
          </Grid>
        ))}
        {folderLists?.length > folderItems?.length && (
          <Grid item xs display="flex">
            <ViewAllCard />
          </Grid>
        )}
      </Grid>
    </FavouriteBrandContainer>
  );
};

export default FavouriteBrandSwipeFile;
