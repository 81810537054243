import { Box, CircularProgress, Typography } from "@mui/material";
import PaymentHeader from "./PaymentHeader";
import { CustomContainer, PlanDetails, PlanHeader } from "./style";
import { NavigatePrevIcon, PaymentTickIcon } from "../../assets/Icons";
import { CustomButton } from "../../globalStyle";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { currentPlanSelector } from "../../store/account/selector";
import { useEffect } from "react";
import { AccountSagaActions } from "../../store/account/sagas";
import routeConstant from "../../utils/routeConstant";
import { useTheme } from "styled-components";
import { AdDrawerStyledButton } from "../commonComponents/adDetails/style";

const PreviewPage = () => {
  const { subscriptionId } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(AccountSagaActions.getCurrentPlan({ id: subscriptionId }));
  }, [subscriptionId]);

  const currentPlan = useSelector(currentPlanSelector);

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FFF",
        gap: "25px",
      }}
    >
      <PaymentHeader />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "25px",
          maxWidth: "1100px",
          marginBottom: "30px",
        }}
      >
        <Box sx={{ display: "flex", width: "100%" }}>
          {currentPlan?._id && (
            <AdDrawerStyledButton onClick={() => navigate(-1)}>
              <NavigatePrevIcon />
              Back
            </AdDrawerStyledButton>
          )}
          <Typography
            style={{
              fontWeight: 700,
              fontSize: "32px",
              color: "#414042",
              textAlign: "center",
              flex: 1,
            }}
          >
            Cart
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap="30px">
          {!currentPlan?.name ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <CircularProgress size="50px" />
            </Box>
          ) : (
            <>
              <CustomContainer>
                <Box>
                  <PlanHeader>
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: 24, fontWeight: 600 }}
                      >
                        {currentPlan?.name}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: 16, fontWeight: 500 }}
                      >
                        {currentPlan?.description}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: 32, fontWeight: 700 }}
                    >
                      ${currentPlan?.amount?.toFixed(2)}
                    </Typography>
                  </PlanHeader>
                  <PlanDetails>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 20,
                        fontWeight: 600,
                        padding: "30px 30px 0",
                      }}
                    >
                      Plan Includes:{" "}
                    </Typography>
                    {currentPlan?.featureList?.map((perk: string) => (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          paddingX: "30px",
                        }}
                      >
                        <PaymentTickIcon />
                        <Box sx={{ fontSize: 16, fontWeight: 500 }}>{perk}</Box>
                      </Box>
                    ))}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: "20px",
                        borderTop: `1px solid ${theme.colors.border1}`,
                        padding: "30px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: 600,
                          color: "#414042",
                        }}
                      >
                        Total : ${currentPlan?.amount?.toFixed(2)}
                      </Typography>
                      <CustomButton
                        style={{
                          fontSize: "16px",
                          color: "white",
                          borderRadius: "25px",
                          background:
                            "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)",
                          padding: "14px 30px",
                          height: "38px",
                        }}
                        onClick={() =>
                          navigate(
                            `${routeConstant?.paymentCheckout}/dropin/${subscriptionId}`
                          )
                        }
                      >
                        Continue to Checkout
                      </CustomButton>
                    </Box>
                  </PlanDetails>
                </Box>
              </CustomContainer>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PreviewPage;
