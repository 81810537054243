import axiosClient from "../apiClient";

export const getCurrentPlanDetails = async ({ id }: { id: string }) => {
  try {
    const response = await axiosClient.get(`/plan/details/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getSession = async (
  type: string,
  currency: string,
  subscriptionId: string
) => {
  try {
    const response = await axiosClient.post(`/payment/sessions?type=${type}`, {
      paymentMethod: type,
      subscriptionId: subscriptionId,
      countryCode: "NL",
      currency: currency,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getPlaymentHistory = async () => {
  try {
    const response = await axiosClient.get(`/payment/list`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getPaymentDetails = async (id: string) => {
  try {
    const response = await axiosClient.get(`/payment/detail/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const cancelSubscription = async () => {
  try {
    const response = await axiosClient.get(`/payment/subscription-cancel`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getPaymentMethods = async () => {
  try {
    const response = await axiosClient.get(`/payment/paymentMethods`);
    return response;
  } catch (error) {
    return error;
  }
};

export const handlePayment = async (data: { [key: string]: any }) => {
  try {
    const response = await axiosClient.post(`/payment/submit-payment`, {
      ...data,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const checkPaymentDone = async () => {
  try {
    const response = await axiosClient.get(`/payment/check-payment-done`);
    return response;
  } catch (error) {
    return error;
  }
};
