import { createSlice } from "@reduxjs/toolkit";
import { PaymentTypes } from "./types";

export const initialState: PaymentTypes = {
  error: "",
  session: null,
  orderRef: null,
  paymentDataStoreRes: null,
  paymentMethods: [],
  config: {
    storePaymentMethod: true,
    paymentMethodsConfiguration: {
      ideal: {
        showImage: true,
      },
      card: {
        hasHolderName: true,
        holderNameRequired: true,
        name: "Credit or debit card",
        amount: {
          value: null,
          currency: null,
        },
      },
    },
    locale: "en_US",
    showPayButton: true,
    clientKey: process.env.REACT_APP_API_ADYEN_CLIENT_KEY || 'test_PF74MFXZRZFCDC7MPPWW7PGWUAXAV2CE',
    environment: "test",
  },
  isLoading: false,
  paymentHistoryList: {
    data: [],
    total: 0,
  },
  paymentDetails: undefined,
  subscriptionSkipLimit: {
    limit: 10,
    skip: 0,
  },
  paymentResponse: null,
};

const payment = createSlice({
  name: "paymentState",
  initialState,
  reducers: {
    setPaymentSession: (state, action) => {
      const [updatedData, status] = action.payload;

      if (status >= 300) state.error = updatedData;
      else {
        state.session = updatedData[0];
        state.orderRef = updatedData[1];
        state.config.paymentMethodsConfiguration.card.amount =
          updatedData[0].amount;
      }
    },
    clearPaymentSession: (state) => {
      state.error = "";
      state.session = null;
      state.orderRef = null;
    },
    paymentDataStore: (state, action) => {
      const [res, status] = action.payload;
      if (status >= 300) {
        state.error = res;
      } else {
        state.paymentDataStoreRes = res;
      }
    },
    setPaymentHistory(state, action) {
      state.paymentHistoryList = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPaymentDetails: (state, action) => {
      state.paymentDetails = action.payload;
    },
    resetSubscriptionSkipLimitValue(state) {
      state.subscriptionSkipLimit.limit = 10;
      state.subscriptionSkipLimit.skip = 0;
    },
    setPaymentMethods: (state, action) => {
      state.paymentMethods = action.payload;
    },
    setpaymentResponse: (state, action) => {  
      state.paymentResponse = action.payload;
    }
  },
});

export const {
  actions: PaymentReducerAction,
  name: PaymentReducerName,
  reducer: PaymentReducer,
} = payment;
