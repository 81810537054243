import { Button, Chip, Divider, MenuItem, Typography } from "@mui/material";
import styled from "styled-components";

export const StyledMenuItem = styled(MenuItem)`
  && {
    color: ${({ theme }) => theme.colors.black2};
    font-weight: 500;
    font-size: 1rem;
    margin-inline: 0.938rem;
    padding: 0.375rem 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    &:hover,
    &.Mui-selected {
      background-color: ${({ theme }) => theme.colors.background3};
      border-radius: 8px;
    }
  }
`;

export const StyledDivider = styled(Divider)`
  && {
    color: #eeeeee;
  }
`;

export const StyledChip = styled(Chip)`
  && {
    background: ${({ theme }) => theme.colors.backgroundPrimary};
  }
`;

export const CustomButton = styled(Button)`
  && {
    text-transform: capitalize;
    min-width: unset;
  }
`;

export const NotificationButton = styled(Button)`
  && {
    text-transform: capitalize;
    min-width: unset;
    font-size: 1.25rem;
    height: 3.375rem;
    padding: 0.937rem 1.875rem;
    border-radius: 6.25rem;
    color: ${({ theme }) => theme.colors.white};
    position: absolute;
    bottom: 1rem;
    transform: translateX(-50%);
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const NotificationMessage1 = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
  }
`;

export const NotificationMessage2 = styled(Typography)`
  && {
    font-weight: 400;
    font-size: 12px;
    margin-top: 0.437rem;
    color: ${({ theme }) => theme.colors.gray1};
    white-space: normal;
    word-wrap: break-word;
  }
`;

export const NotificationCloseButtonWrapper = styled(Button)`
  && {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    margin-left: auto;
    padding: 0;
    min-width: auto;
  }
`;
