import {
  Checkbox,
  CircularProgress,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "styled-components";
import FilterSearchbox from "../commonComponents/filterSearchbox";
import { StyledFormGroup } from "../popover/style";
import { CheckedIcon, CreateNewIcon, UncheckedIcon } from "../../assets/Icons";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  boardIdsSelector,
  boardItemsSelector,
  isLoadingSelector,
  loadingBoardSelector,
  saveAdPopupAnchorElSelector,
  selectedAdIdSelector,
} from "../../store/metadata/selector";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { ButtonText, HeaderButton2 } from "../../pageComponents/style";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import useDebounce from "../../hooks/debounce";

const SaveAdPopover = ({ reFetchData }: { reFetchData?: Function }) => {
  const [removedBoardId, setRemovedBoardId] = useState<string[]>([]);
  const [boardSearchText, setBoardSearchText] = useState("");
  const theme = useTheme();
  const dispatch = useDispatch();
  const boardItems = useSelector(boardItemsSelector);
  const selectedBoardIds = useSelector(boardIdsSelector);

  const anchorEl = useSelector(saveAdPopupAnchorElSelector);

  const selectedAdId = useSelector(selectedAdIdSelector);

  const loadingBoard = useSelector(loadingBoardSelector);

  // Use debounce for the search text
  const debouncedSearchText = useDebounce(boardSearchText, 1000);

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    boardId: string
  ) => {
    const { checked } = event.target;
    dispatch(MetaDataReducerAction.setBoardId(boardId));

    checked === false &&
      setRemovedBoardId((prevState) => [...prevState, boardId]);
  };

  const handlePopoverClose = () => {
    setRemovedBoardId([]);
    dispatch(MetaDataReducerAction.setSaveAdPopupAnchorEl(null));
    dispatch(MetaDataReducerAction.setBoardIdstoInitialSet([]));
    setBoardSearchText("");
    dispatch(MetaDataSagaActions.fetchBoardList({ search: "" }));
  };

  const handleSubmit = () => {
    dispatch(
      MetaDataSagaActions.saveAdToBoards({
        adId: selectedAdId,
        boardIds: selectedBoardIds,
        removeBoardIds: removedBoardId,
        reFetchData,
      })
    );
    setBoardSearchText("");
  };

  // Fetch board list when debounced search text changes
  useEffect(() => {
    if (debouncedSearchText) {
      dispatch(MetaDataSagaActions.fetchBoardList({ search: debouncedSearchText }));
    } else {
      dispatch(MetaDataSagaActions.fetchBoardList({ search: "" }));
    }
  }, [debouncedSearchText, dispatch]);

  const handleBoardSearchOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBoardSearchText(event.target.value);
  };

  const handleBoardSearch = () => {
    dispatch(MetaDataSagaActions.fetchBoardList({ search: boardSearchText }));
  };

  const handleBoardSearchKeyDown: React.KeyboardEventHandler<
    HTMLInputElement
  > = (event) => {
    event.stopPropagation();
    if (event.key === "Enter") 
    setBoardSearchText(boardSearchText);
  };

  // Separate selected and non-selected boards
  const selectedBoards = boardItems.filter((board: any) =>
    selectedBoardIds.includes(board._id)
  );
  const nonSelectedBoards = boardItems.filter(
    (board: any) => !selectedBoardIds.includes(board._id)
  );

  // Combine the two arrays, with selected boards at the top
  const sortedBoardItems = [...selectedBoards, ...nonSelectedBoards];

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handlePopoverClose}
      variant="selectedMenu"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        ".MuiMenu-paper": {
          borderRadius: "0.938rem",
          padding: "1.375rem 1.25rem 1.25rem 1.25rem",
          width: "23%",
        },
        ".MuiList-root": {
          padding: "0",
          display: "flex",
          flexDirection: "column",
          gap: "0.938rem",
        },
      }}
    >
      <Typography
        fontSize="1.125rem"
        fontWeight="700"
        color={theme.colors.black2}
      >
        {selectedBoardIds.length > 0 ? 'Saved' : 'Save'} to Board
      </Typography>
      <StyledFormGroup>
        <FilterSearchbox
          placeholder="Search"
          value={boardSearchText}
          handleChange={handleBoardSearchOnChange}
          handleKeyDown={handleBoardSearchKeyDown}
          handleSearchIconClick={handleBoardSearch}
        />
        {sortedBoardItems?.map((board: any, index: any) => (
          <Stack
            key={index}
            direction="row"
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Typography
              fontSize="0.938rem"
              fontWeight="500"
              color={theme.colors.black2}
            >
              {board.name}
            </Typography>
            <Checkbox
              key={index}
              onChange={(event) => onChangeHandler(event, board._id)}
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              sx={{ padding: 0 }}
              checked={selectedBoardIds.includes(board._id)}
            />
          </Stack>
        ))}
      </StyledFormGroup>
      <HeaderButton2
        variant="contained"
        size="small"
        disabled={loadingBoard || selectedBoardIds?.length === 0}
        onClick={handleSubmit}
        startIcon={loadingBoard && <CircularProgress size={20} color="inherit" />}
      >
        <ButtonText>Update</ButtonText>
      </HeaderButton2>
      <Stack
        direction="row"
        sx={{ alignItems: "center", gap: "0." }}
        onClick={() => dispatch(MetaDataReducerAction.setOpenBoardModal(true))}
      >
        <CreateNewIcon fill={theme.colors.black2} />
        <Typography
          fontSize="1rem"
          fontWeight="600"
          color={theme.colors.black2}
          style={{
            cursor: "pointer",
          }}
        >
          Create New Board
        </Typography>
      </Stack>
    </Menu>
  );
};

export default memo(SaveAdPopover);
