import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { ProcessingPaymentIcon, SuccessPaymentIcon } from "../../assets/Icons"; // Assuming you have these icons
import PaymentHeader from "./PaymentHeader";
import { CustomButton } from "../../globalStyle";
import { Link, useNavigate } from "react-router-dom"; // Using Link for routing
import routeConstant from "../../utils/routeConstant";
import { AccountSagaActions } from "../../store/account/sagas";
import { userDetailsSelector } from "../../store/account/selector";
import { paymentResponseSelector } from "../../store/payment/selector";
import { PaymentSagaActions } from "../../store/payment/sagas";

type StatusPagePropsType = {
  type: string;
  reason: string;
};

const StatusPage = ({ type, reason }: StatusPagePropsType) => {
  let msg, img, route;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector(userDetailsSelector);
  const paymentResponse = useSelector(paymentResponseSelector);
  const user = JSON.parse(localStorage.getItem("User") as string);
  switch (type) {
    case "pending":
      msg = (
        <span>Your order has been received! Payment completion pending.</span>
      );
      img = <ProcessingPaymentIcon />;
      route = routeConstant.dashboard;
      break;
    case "failed":
      msg = (
        <span>
          The payment was refused. Please try a different payment method or
          card.
        </span>
      );
      img = <ProcessingPaymentIcon />;
      route = routeConstant.subscription;
      break;
    case "error":
      msg = (
        <span>
          Error! Reason: {reason || "Internal error"}, refer to&nbsp;
          {/* TODO: Remove before going live */}
          <a
            href="https://docs.adyen.com/development-resources/response-handling"
            target="_blank"
            rel="noopener noreferrer"
          >
            Response handling.
          </a>
        </span>
      );
      img = <ProcessingPaymentIcon />;
      route = routeConstant.subscription;
      break;
    default:
      msg = <span>Your order has been successfully placed.</span>;
      img = <SuccessPaymentIcon />;
      route = routeConstant.dashboard;
  }

  useEffect(() => {
    if (paymentResponse?.status === "COMPLETED") {
      navigate(`${routeConstant?.paymentStatus}/success`, { replace: true });
    } else {
      setTimeout(() => {
        dispatch(PaymentSagaActions.CheckPaymentDone());
      }, 5000);
    }
  }, [paymentResponse]);

  useEffect(() => {
    if (type === "success") {
      dispatch(
        AccountSagaActions.fetchUserDetails(userDetails?._id ?? user?.user?._id)
      );
    }
  }, [type]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        gap: "170px",
        backgroundColor: "#FFF",
      }}
    >
      <PaymentHeader />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "40px",
        }}
      >
        {img}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            style={{
              fontWeight: 700,
              fontSize: "32px",
              lineHeight: "39px",
              color: "#414042",
              textAlign: "center",
            }}
          >
            {type === "success"
              ? "Payment done successfully!"
              : type === "pending"
              ? "Payment Pending!"
              : "Payment failed!"}
          </Typography>
          <Typography
            style={{
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "30px",
              textAlign: "center",
              color: "#58595B",
            }}
          >
            {msg}
          </Typography>
          <Link to={route === "/" ? route : `/${route}`}>
            <CustomButton
              style={{
                marginTop: "40px",
                fontSize: "16px",
                color: "white",
                borderRadius: "25px",
                background:
                  "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)",
                padding: "10px 30px",
                height: "38px",
              }}
            >
              {type === "success" || type === "pending"
                ? "Go to Home Page"
                : "Go back to Subscriptions"}
            </CustomButton>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default StatusPage;
