import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { getRedirectUrl } from "../../utils/redirect";
import { Box, CircularProgress, Typography } from "@mui/material";
import {
  paymentMethodsListSelector,
  paymentResponseSelector,
  paymentSelector,
} from "../../store/payment/selector";
import { PaymentSagaActions } from "../../store/payment/sagas";
import routeConstant from "../../utils/routeConstant";
import PaymentHeader from "./PaymentHeader";

export const PaymentCheckout = () => {
  return <Checkout />;
};

const Checkout = () => {
  const dispatch = useDispatch();
  const { type, subscriptionId } = useParams();
  const payment = useSelector(paymentSelector);
  const navigate = useNavigate();

  const paymentContainer = useRef(null);
  const paymentMethods = useSelector(paymentMethodsListSelector);

  const BASE_URL = `${window.location.protocol}//${window.location.host}`;

  useEffect(() => {
    if (subscriptionId) dispatch(PaymentSagaActions.GetPaymentMethods());
  }, [dispatch, subscriptionId]);

  useEffect(() => {
    if (type && subscriptionId)
      dispatch(
        PaymentSagaActions.initiateCheckout({ type, subscriptionId, BASE_URL })
      );
  }, [dispatch, type, subscriptionId]);

  useEffect(() => {
    const { error } = payment;

    if (error) {
      navigate(`${routeConstant?.paymentStatus}/error?reason=${error}`, {
        replace: true,
      });
    }
  }, [payment, navigate]);

  useEffect(() => {
    const { config, session } = payment;
    let ignore = false;

    if (!session || !paymentContainer.current) {
      // initiateCheckout is not finished yet.
      return;
    }

    const createCheckout = async () => {
      const checkout = await AdyenCheckout({
        ...config,
        session,
        paymentMethodsResponse: { paymentMethods },
        onSubmit: async (state, element) => {
          const handleNavigate = (url: string) =>
            navigate(getRedirectUrl(url), { replace: true });
          const data = {
            ...state,
            amount: session?.amount,
            metadata: session?.metadata,
            returnUrl: session?.returnUrl,
            shopperReference: session?.shopperReference,
          };
          await dispatch(
            PaymentSagaActions.handleCheckoutPayment({ data, handleNavigate })
          );
        },
        onPaymentCompleted: (
          response: { resultCode: string },
          _component: any
        ) => navigate(getRedirectUrl(response.resultCode), { replace: true }),
        onError: (error: { message: any }, _component: any) => {
          console.error(error);
          navigate(
            `${routeConstant?.paymentStatus}/error?reason=${error.message}`,
            {
              replace: true,
            }
          );
        },
      });

      // The 'ignore' flag is used to avoid double re-rendering caused by React 18 StrictMode
      // More about it here: https://beta.reactjs.org/learn/synchronizing-with-effects#fetching-data
      if (paymentContainer.current && !ignore && type) {
        checkout.create(type).mount(paymentContainer.current);
      }
    };
    if (payment.session) createCheckout();

    return () => {
      ignore = true;
    };
  }, [payment, type, navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#FFF",
        gap: "25px",
      }}
    >
      <PaymentHeader />
      <Box>
        {!payment.session ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <CircularProgress size="50px" />
          </Box>
        ) : (
          <Box
            ref={paymentContainer}
            sx={{
              width: "650px",
              padding: "30px",
              display:
                type?.includes("google") || type?.includes("apple")
                  ? "grid"
                  : "block",
              justifyContent: "center",
            }}
          ></Box>
        )}
      </Box>
    </Box>
  );
};
