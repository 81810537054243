import { Button, CircularProgress } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createRangeSelection,
  $nodesOfType,
  CLEAR_EDITOR_COMMAND,
  TextNode,
} from "lexical";
import { useTheme } from "styled-components";
import { useCallback } from "react";
import { $generateHtmlFromNodes } from "@lexical/html";
import { useSelector } from "react-redux";
import { isLoadingSelector } from "../../../store/metadata/selector";

const SendButton = ({
  handleAddComment,
  editorText,
}: {
  handleAddComment: (comment: string) => void;
  editorText?: string;
}) => {
  const theme = useTheme();
  const [editor] = useLexicalComposerContext();
  const loading = useSelector(isLoadingSelector);

  const handleSendComment = useCallback(() => {
    editor.update(() => {
      let htmlString = "";
      const nodes = $nodesOfType(TextNode);
      for (const tNode of nodes) {
        const rangeSelection = $createRangeSelection();
        rangeSelection.setTextNodeRange(tNode, 0, tNode, tNode.__text.length);
        const html = $generateHtmlFromNodes(editor, rangeSelection);
        htmlString += html;
      }
      handleAddComment(htmlString);
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    });
  }, [editor]);

  return (
    <Button
      startIcon={
        loading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <SendIcon fontSize="small" />
        )
      }
      sx={{
        color: theme.colors.black2,
        fontWeight: "600",
        textTransform: "capitalize",
        background: ` ${theme.colors.background4} !important`,
      }}
      onClick={handleSendComment}
      disabled={!editorText}
    >
      Send
    </Button>
  );
};

export default SendButton;
