import { InputAdornment, Select } from "@mui/material";
import { ArrowDownIcon } from "../../assets/Icons";
import { useTheme } from "styled-components";
import { SelectDropdownProps } from "../../utils/propTypes";
import { useEffect, useState } from "react";

const SelectDropdown = ({
  children,
  startAdornmentIcon,
  handleChange,
  multiple = false,
  value = [],
  renderValue,
  loadingState,
  handleClose = () => {},
  openDropdown = "",
  height,
  menuListPadding,
}: SelectDropdownProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDropdownClose = () => {
    setOpen(false);
    handleClose();
  };
  const handleDropdownOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (openDropdown === "false") {
      setOpen(false);
      handleClose();
    }
  }, [openDropdown]);

  return (
    <Select
      open={open}
      multiple={multiple}
      value={value}
      onChange={handleChange}
      onClose={handleDropdownClose}
      onOpen={handleDropdownOpen}
      renderValue={renderValue}
      sx={{
        width: "100%",
        height: "2.875rem",
        borderRadius: "23px",
        backgroundColor: "white",
        ".MuiOutlinedInput-notchedOutline": {
          border: `0.95px solid ${theme.colors.border1} !important`,
        },
        ".MuiSelect-icon": {
          margin: "0.25rem 0.625rem 0 0",
        },
      }}
      MenuProps={{
        PaperProps: {
          style: {
            borderRadius: "0.938rem",
          },
        },
        MenuListProps: {
          style: {
            overflow: "auto",
            maxHeight: `${height ?? "15rem"}`,
            ...(menuListPadding && { padding: menuListPadding }),
          },
        },
      }}
      startAdornment={
        <InputAdornment position="start">{startAdornmentIcon}</InputAdornment>
      }
      IconComponent={(props) => <ArrowDownIcon className={props.className} />}
      disabled={loadingState}
    >
      {children}
    </Select>
  );
};

export default SelectDropdown;
