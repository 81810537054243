import {
  CardContainer,
  DashboardCardButton,
  GrayBgHeader,
  GrayBgHeaderText,
  TeamsCollectionHeader,
} from "./style";
import { Box, Chip, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "styled-components";
import { StyledDivider } from "../../globalStyle";
import {
  ArrowRightIcon,
  FavouriteAdseBlackIcon,
  HelpBlackIcon,
  NoFavoriteBrandsFoundIcon,
  NoSwipeAdsTeamsCollectionFoundIcon,
  SideArrow,
} from "../../assets/Icons";
import BrandFolderCard from "../commonComponents/brandCards/brandFolderCard";
import { useDispatch, useSelector } from "react-redux";
import {
  favouriteBrandsSelector,
  folderListsSelector,
} from "../../store/metadata/selector";
import { brandItem, folderItem } from "../../store/metadata/types";
import { useState } from "react";
import {
  BrandCountWrapper,
  BrandDetailWrapper,
  BrandLogoWrapper,
  BrandName,
  FavouriteBrandImageWrapper,
  TopBrandCardContainer,
} from "../commonComponents/brandCards/style";
import { formatAdCount } from "../../utils/helpers";
import FacebookLogo from "../../assets/images/facebookLogo.png";
import LinkedInLogo from "../../assets/images/linkedInLogo.png";
import { Link, useNavigate } from "react-router-dom";
import { DiscoverySagaActions } from "../../store/discovery/sagas";
import { DiscoveryReducerAction } from "../../store/discovery/slice";
import routeConstant from "../../utils/routeConstant";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import TeamSelectDropdown from "../teamSelectDropdown";
import { userDetailsSelector } from "../../store/account/selector";
import { CustomTooltip } from "../commonComponents/customTooltip";

const SwipeAdSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const folderList = useSelector(folderListsSelector);

  const favouriteBrandsList = useSelector(favouriteBrandsSelector);

  const userCurrentTeamDetail = useSelector(userDetailsSelector);

  const handleChangeTeam = (teamId: string) => {
    dispatch(MetaDataSagaActions.changeTeam({ teamId }));
  };

  const handleViewBrandAds = (brandId: string) => {
    dispatch(
      DiscoverySagaActions.fetchBrandDiscoveryItems({
        brandId,
      })
    );
    navigate(`${routeConstant.discovery}#discovery?${brandId}`);
  };

  return (
    <CardContainer sx={{ padding: "0" }}>
      <Stack direction="row" margin="0" flexWrap={"wrap"}>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          borderRight={`1px solid ${theme.colors.border1}`}
        >
          <Box flex={1}>
            <GrayBgHeader>
              <GrayBgHeaderText>Favourite</GrayBgHeaderText>
            </GrayBgHeader>

            <Grid
              container
              spacing={"1rem"}
              padding="0 0px 0rem 3rem"
              sx={{
                marginTop: "2rem",
                marginBottom: "2rem",
                overflowY: "auto",
                height: "26rem",
                flexGrow: 1, // Makes the grid section grow to fill available space
              }}
              display="flex"
              gap="20px"
              width="100%"
            >
              {favouriteBrandsList?.length > 0 ? (
                favouriteBrandsList?.map((item: brandItem) => (
                  <TopBrandCardContainer
                    style={{ marginInline: "0px", minWidth: "auto" }}
                  >
                    <FavouriteBrandImageWrapper>
                      <BrandDetailWrapper style={{ margin: "10px 0" }}>
                        <BrandLogoWrapper
                          onClick={() => handleViewBrandAds(item?._id)}
                        >
                          <img
                            src={item?.logo}
                            alt="brand"
                            style={{
                              maxWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </BrandLogoWrapper>
                        <BrandName
                          fontWeight={600}
                          fontSize={"16px"}
                          onClick={() => handleViewBrandAds(item?._id)}
                        >
                          {item?.name}
                        </BrandName>
                        <BrandCountWrapper style={{ marginTop: "5px" }}>
                          <span
                            style={{
                              height: "15px",
                              width: "15px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "4px",
                              background: "black",
                              marginRight: "8px",
                            }}
                          >
                            <FavouriteAdseBlackIcon />
                          </span>
                          <p
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "1.371rem",
                              padding: "0",
                              margin: "0",
                            }}
                          >
                            {formatAdCount(item?.adsSaved ?? 0)} Saved
                          </p>
                        </BrandCountWrapper>
                      </BrandDetailWrapper>
                    </FavouriteBrandImageWrapper>
                  </TopBrandCardContainer>
                ))
              ) : (
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="20px"
                    alignItems="center"
                  >
                    <NoFavoriteBrandsFoundIcon />
                    <Box fontSize="16px" fontWeight={500}>
                        Add brands to favorite and be able to spy on them
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
          </Box>
          <Box>
            <GrayBgHeader>
              <GrayBgHeaderText
                sx={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                Saving Extension Status
                <CustomTooltip title="Save Media (Videos and Photos) from Facebook Ad Library and LinkedIn Ad Library from below links. 
You find interesting Ads on Instagram? Got you covered! Our Swipe Lounge Chrome Extension saves Instagram Posts as well.">
                  <IconButton>
                    <HelpBlackIcon />
                  </IconButton>
                </CustomTooltip>
              </GrayBgHeaderText>
            </GrayBgHeader>

            <Box
              display="flex"
              gap="20px"
              padding="20px"
              marginTop="2rem"
              marginBottom="2rem"
            >
              <Box
                borderRadius="10px"
                border={`1px solid ${theme.colors.border1}`}
                width="50%"
              >
                <Box display="flex" gap="15px" padding="15px" paddingBottom={0}>
                  <Box>
                    <img
                      src={FacebookLogo}
                      alt="pp"
                      width="48px"
                      height="48px"
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                      Facebook Ad Library
                    </Typography>
                    <Box>
                      <Chip
                        label="Working"
                        sx={{
                          height: "23px",
                          borderRadius: "20px",
                          background: "#EDFCF5",
                          border: "1px solid #D4F7E6",
                          color: "#1EAF83",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box width="100%" display="flex" justifyContent="end">
                  <Link
                    target="_blank"
                    to="https://www.facebook.com/ads/library"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "34px",
                      height: "34px",
                      borderRadius: "10px 0 9px 0",
                      background: theme.colors.border3,
                    }}
                  >
                    <SideArrow />
                  </Link>
                </Box>
              </Box>
              <Box
                borderRadius="10px"
                border={`1px solid ${theme.colors.border1}`}
                width="50%"
              >
                <Box display="flex" gap="15px" padding="15px" paddingBottom={0}>
                  <Box>
                    <img
                      src={LinkedInLogo}
                      alt="pp"
                      width="48px"
                      height="48px"
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                      LinkedIn Ads
                    </Typography>
                    <Box>
                      <Chip
                        label="Working"
                        sx={{
                          height: "23px",
                          borderRadius: "20px",
                          background: "#EDFCF5",
                          border: "1px solid #D4F7E6",
                          color: "#1EAF83",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box width="100%" display="flex" justifyContent="end">
                  <Link
                    target="_blank"
                    to="https://www.linkedin.com/ad-library/"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "34px",
                      height: "34px",
                      borderRadius: "10px 0 9px 0",
                      background: theme.colors.border3,
                    }}
                  >
                    <SideArrow />
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box flex={1} display={"flex"} flexDirection={"column"}>
          <TeamsCollectionHeader
            style={{
              height:
                userCurrentTeamDetail?.userTeams?.length === 0 ? "3rem" : "",
            }}
          >
            <GrayBgHeaderText>Teams Collection</GrayBgHeaderText>
            {userCurrentTeamDetail?.userTeams?.length > 0 && (
              <TeamSelectDropdown
                onChange={handleChangeTeam}
                sx={{
                  borderRadius: "25px",
                  border: "1px solid",
                  margin: "0",
                  marginRight: "1rem",
                  "& .MuiInputBase-input": {
                    padding: "0.625rem 1.25rem !important",
                    paddingRight: "2rem !important",
                  },
                  "& .MuiSelect-icon": {
                    right: "0.5rem",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiMenuItem-root": {
                    padding: "0.25rem 0.5rem !important",
                  },
                  "& .MuiList-padding": {
                    padding: "0 !important",
                  },
                }}
              />
            )}
          </TeamsCollectionHeader>
          <Box
            paddingY="1.5rem"
            paddingX="0.875rem"
            sx={{
              overflowY: "auto",
              height:
                userCurrentTeamDetail?.userTeams?.length === 0
                  ? "45.7rem"
                  : "44.625rem",
            }}
          >
            {folderList?.teamFolders?.length > 0 ? (
              <Grid container>
                {folderList?.teamFolders?.map(
                  (item: folderItem, index: number) => (
                    <Grid item xs={4} xl={3} key={index} padding={"10px"}>
                      <BrandFolderCard item={item} ableToFavourite={false} />
                    </Grid>
                  )
                )}
              </Grid>
            ) : (
              <NoSwipeAdsTeamsCollectionFoundIcon />
            )}
          </Box>
          <StyledDivider />
        </Box>
      </Stack>
    </CardContainer>
  );
};

export default SwipeAdSection;
