import { CircularProgress, DialogTitle, Divider, Stack } from "@mui/material";
import {
  CloseIconWrapper,
  CreateBoardWrapper,
  FontGrayNormal,
  ModalIconWrapper,
  ModalTitle,
  StyledContainedButton,
  StyledDialog,
  StyledFormControl,
  StyledInputLabel,
  StyledTextField,
} from "./style";
import { CloseIcon, CreateFolderIcon } from "../../assets/Icons";
import { CreateFolderValueProps, ModalProps } from "../../utils/propTypes";
import { useDispatch, useSelector } from "react-redux";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createFolderValidationSchema } from "../../utils/formValidationSchema";
import { ErrorMessage } from "../../pageComponents/auth/style";
import {
  folderItemSelector,
  isLoadingSelector,
  openCreateFolderSelector,
} from "../../store/metadata/selector";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { webkitInputCSSFix } from "../../utils/helpers";

const CreateFolderModal = () => {
  const folderItem = useSelector(folderItemSelector);
  const {
    getValues,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CreateFolderValueProps>({
    defaultValues: {
      name: folderItem?.name,
    },
    resolver: yupResolver(createFolderValidationSchema),
  });

  const dispatch = useDispatch();
  const loading = useSelector(isLoadingSelector);
  const openModal = useSelector(openCreateFolderSelector);

  const onSubmit = () => {
    const values = getValues();
    if (folderItem) {
      dispatch(
        MetaDataSagaActions.updateFolder({
          name: values.name,
          folderId: folderItem._id,
        })
      );
    } else {
      dispatch(MetaDataSagaActions.createFolder(values));
    }
  };

  const handleCloseModal = () => {
    dispatch(MetaDataReducerAction.setOpenCreateFolder(false));
    dispatch(MetaDataReducerAction.setFolderItem(null));
  };

  return (
    <StyledDialog
      onClose={handleCloseModal}
      open={openModal}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ padding: "1.875rem 1.875rem 1.25rem 1.875rem" }}>
        <Stack direction="row" gap="0.75rem" alignItems="start">
          <ModalIconWrapper>
            <CreateFolderIcon fill="white" />
          </ModalIconWrapper>
          <Stack>
            <ModalTitle>
              {folderItem ? "Update Folder" : "Create Folder"}
            </ModalTitle>
            <FontGrayNormal>Folders keep your boards organized</FontGrayNormal>
          </Stack>
        </Stack>
        <CloseIconWrapper aria-label="close" onClick={handleCloseModal}>
          <CloseIcon />
        </CloseIconWrapper>
      </DialogTitle>
      <Divider sx={{ borderColor: "#E3E6EA" }} />
      <CreateBoardWrapper>
        <StyledFormControl fullWidth>
          <StyledInputLabel htmlFor="name">Folder Name</StyledInputLabel>
          <StyledTextField
            id="name"
            variant="outlined"
            {...register("name", { required: true })}
            sx={webkitInputCSSFix}
          />
          {errors.name?.message && (
            <ErrorMessage>{errors.name?.message}</ErrorMessage>
          )}
        </StyledFormControl>
      </CreateBoardWrapper>
      <Divider sx={{ borderColor: "#E3E6EA" }} />
      <StyledContainedButton
        variant="contained"
        size="small"
        sx={{
          margin: "1.875rem 1.875rem 1.875rem auto",
          padding: "0.313rem 2.375rem",
        }}
        onClick={handleSubmit(onSubmit)}
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} color="inherit" />}
      >
        {folderItem ? "Update Folder" : "Create Folder"}
      </StyledContainedButton>
    </StyledDialog>
  );
};

export default CreateFolderModal;
