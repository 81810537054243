import {
  Badge,
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
  Card,
} from "@mui/material";
import styled from "styled-components";
import { CustomButton } from "../globalStyle";
import { NavLink } from "react-router-dom";

export const HeaderButton1 = styled(CustomButton)`
  border-radius: 1.786rem !important;
  color: ${({ theme }) => theme.colors.backgroundPrimary} !important;
  width: 9rem;
  height: 2.6rem;
  &:hover {
    background: ${({ theme }) => theme.colors.backgroundPrimary};
    color: ${({ theme }) => theme.colors.white} !important;;
  }
  && {
    padding: 0.715rem 1.428rem;
  }
`;

export const HeaderButton2 = styled(CustomButton)`
  border-radius: 1.786rem !important;
  color: white !important;
  background: ${({ theme }) => theme.colors.backgroundPrimary} !important;
  height: 2.6rem;
  && {
    padding: 0.715rem 1.428rem;
  }
`;

export const ListContainer = styled(Box)`
  padding: 2rem;
  background:  ${({ theme }) => theme.colors.gray97} !important;
`;

export const Tablecontainer = styled.div`
  max-height: 70vh;
  overflow: auto;
  background-color: white;
`;

export const Container = styled.div``;

export const HeaderSection = styled.div`
  width: 100%;
  height: fit-content;
`;

export const HeaderMenuSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  border-bottom: 1px solid #eaecee;
  height: 5.714rem;
  background-color: white;
`;

export const HeaderLeftMenuSection = styled.div`
  display: flex;
`;

export const HamburgerMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.93rem 2.143rem 1.93rem 1.143rem;
  border-right: 1px solid #eaecee;
  cursor: pointer;
`;

export const HeaderTitleSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.143rem 1.86rem;
  gap: 0.286rem;
`;

export const HeaderRightMenuSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.857rem;
`;

export const HeaderTitle = styled(Typography)`
  && {
    font-size: 1.43rem;
    font-weight: 700;
    line-height: 1.741rem;
    color: ${({ theme }) => `${theme.colors.black2}`};
  }
`;

export const HeaderSubTitle = styled(Typography)`
  && {
    font-size: 1.143rem;
    font-weight: 400;
    line-height: 1.393rem;
    color: ${({ theme }) => `${theme.colors.gray1}`};
  }
`;

export const ButtonText = styled(Typography)`
  font-size: 1rem !important;
  margin: 0.2rem !important;
`;
export const SearchFilterSection = styled.div<{
  isSticky?: boolean;
  drawerOpen?: boolean;
}>`
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 0.5rem 2rem !important;
  border-bottom: 0.5px solid #eaecee !important;
  background:  ${({ theme }) => theme.colors.gray97} !important;
  ${({ isSticky, drawerOpen, theme }) =>
    isSticky &&
    `position:fixed;  
    top: 0;
    z-index: 1000;
    background-color: ${theme.colors.background4};
    width: ${drawerOpen ? "calc(100vw - 21rem)" : "calc(100vw - 12rem)"};
    // border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 13.6533px 13.6533px,
    rgba(0, 0, 0, 0.08) 0px -7px 10px, rgba(0, 0, 0, 0.08) 0px 4px 6px,
    rgba(0, 0, 0, 0.08) 0px 7px 13px, rgba(0, 0, 0, 0.08) 0px -3px 5px;`};
`;

export const SearchWrapper = styled(FormControl)`
  margin: 0.625rem;
  width: 30%;
  height: 2.5rem;
`;

export const SearchIconWrapper = styled(InputAdornment)`
  position: absolute;
  right: 0;
  background-color: black;
  color: white !important;
  padding: 0.25rem;
  height: 100% !important;
  width: 10%;
  border-radius: 0 20px 20px 0;
  display: flex;
  border: 0.5px solid #eaecee !important;
  justify-content: center;
  align-items: center;
`;

export const SearchInput = styled(OutlinedInput)`
  border-radius: 20px !important;
  height: 100%;
  background: white;
`;

export const OptionSection = styled.div`
  display: flex;
  gap: 0.625rem;
  align-items: center;
`;
export const ViewTypeSelection = styled.div`
  border: 0.5px solid #eaecee !important;
  height: 2.5rem;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ViewButton1 = styled.button<{ selected: boolean }>`
  padding: 0.188rem 0.5rem;
  border-radius: 20px 0 0 20px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border: 0;
  background-color: ${({ selected }) => (selected ? "#081720" : "white")};
  cursor: pointer;
`;
export const ViewButton2 = styled.button<{ selected: boolean }>`
  padding: 0.188rem 0.5rem;
  height: 100%;
  background-color: ${({ selected }) => (selected ? "#081720" : "white")};
  border-radius: 0 20px 20px 0;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const OptionButtonText = styled(Typography)`
  font-size: 0.875rem !important;
  text-transform: math-auto;
  font-weight: bold !important;
`;

export const AppliedFilterText = styled(Typography)`
  font-size: 0.875rem !important;
  text-transform: math-auto;
  color: ${({ color }) => `${color}`} !important;
  font-weight: 600 !important
`;
export const OptionButton = styled(Button)`
  text-transform: math-auto;
  display: flex;
  gap: 0.625rem;
  && {
    border-radius: 20px;
    border: 0.5px solid #eaecee;
    height: 2.5rem;
    color: ${({ theme }) => `${theme.colors.black2}`};
    padding: 0.714rem 1.071rem;
    background: white !important;
  }
`;

export const ActiveBadge = styled(Badge)<{ margin: string }>`
  && {
    .MuiBadge-dot {
      position: static;
      margin: ${({ margin }) => margin};
      background-color: ${({ theme }) => theme.colors.green};
      box-shadow: 0 0 0 2px rgba(59, 176, 113, 0.2);
      height: 0.5rem;
      min-width: 0.5rem;
    }
  }
`;

export const InActiveBadge = styled(Badge)<{ margin: string }>`
  && {
    .MuiBadge-badge {
      position: static;
      margin: ${({ margin }) => margin};
      background-color: ${({ theme }) => theme.colors.darkGray};
      box-shadow: 0 0 0 2px #eeeef0;
      height: 0.5rem;
      min-width: 0.5rem;
    }
  }
`;

export const TopBrandsListWrapper = styled.div`
  margin-block: 1.875rem;
`;

export const BrandCountText = styled(Typography)`
  && {
    font-size: 1.125rem;
    color: ${({ theme }) => theme.colors.black2};
    font-weight: 700;
  }
`;

export const BrandGridContainer = styled(Grid)`
  && {
    margin: 1.25rem 0 1.875rem -0.625rem;
  }
`;

export const NotificationBadge = styled(Badge)<{ margin: string }>`
  && {
    .MuiBadge-badge {
      position: absolute;
      background-color: ${({ theme }) => theme.colors.radicalRed};
      height: 0.5rem;
      min-width: 0.5rem;
      margin: ${({ margin }) => margin};
      top: -1.5rem;
      right: -1.95rem;
    }
  }
`;
export const TermsContainer = styled(Card)`
  && {
    min-height: 100vh;
    border-radius: 0.6rem;
  }
`;
export const TermsTitle = styled(Typography)`
  && {
    color: ${({theme}) => theme.colors.black2};
    font-weight: 700;
    font-size: 1.25rem;
  }
`;

export const TermsSubTitle = styled(Typography)`
  && {
    color: ${({theme}) => theme.colors.gray1};
    font-weight: 400;
    font-size: 1rem;
  }
`;

export const TermsHeader = styled(Box)`
  && {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 3.75rem;
    padding: 1.875rem 1rem;
  }
`;
/* Group */

export const TermsLogoText = styled.img`
  && {
   height: 1.455rem;
    width: 9.974rem;
  }
`;

export const TermsContent = styled(Box)`
  && {
  display: flex;
    align-items: center;
    justify-content: center;
    background: ${({theme}) => theme.colors.background4};
  }
`;
export const TermsContentInner = styled(Box)`
  && {
  width: 100rem;
  margin:1.875rem auto;
  background: ${({theme}) => theme.colors.white};
  border: 1px solid ${({theme}) => theme.colors.border1};
    border-radius: 1rem;
  }
`;

export const TermsInnerHeaderText = styled(Typography)`
  && {
    color: ${({theme}) => theme.colors.black2};
    font-weight: 600;
    font-size: 1.375rem;
  }
`;
export const TermsSubText = styled(Typography)`
  && {
    color: ${({theme}) => theme.colors.black2};
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.875rem;
  }
`;
export const ListItemText = styled.li`
    margin: 0.625rem 0;
`;

export const TermsTextContainer = styled(Box)`
  && {
    margin-top: 2rem
  }
`;

export const HomeLink = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 0.737rem;
`