import {
  Box,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { BoardIcon, OpenFolder } from "../../assets/Icons";
import { FolderListItem } from "../../pageComponents/auth/style";
import useConfig from "../../hooks/useConfig";
import { folderItem, selectFolderBoardItem } from "../../store/metadata/types";
import { useDispatch, useSelector } from "react-redux";
import {
  folderItemSelector,
  selectedTabIndexSelector,
  selectFolderSelector,
  sharedFolderListSelector,
} from "../../store/metadata/selector";
import routeConstant from "../../utils/routeConstant";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { useTheme } from "styled-components";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import { SwipeFileReducerAction } from "../../store/swipeFile/slice";
import { useState } from "react";

const SidebarSharedFolders = () => {
  const { drawerOpen } = useConfig();
  const { pathname } = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sharedFolder = useSelector(sharedFolderListSelector);
  const [selectedActionFolder, setSelectedActionFolder] =
    useState<folderItem>();
  const [isEllipsesOpen, setIsEllipsesOpen] = useState<string | null>(null);
  const constants = {
    sharedFolders: "Shared Folders",
  };
  const selectedFolder = useSelector(folderItemSelector);
  const selectedTabIndex = useSelector(selectedTabIndexSelector);
  const selectedFolderId = pathname.split("/")[2];
  const selectedFolderName = pathname.split("/")[1];
  const isSelectedInCurrentFolder =
    selectedFolderName === Object.keys(constants)[0];

  /* const handleFolderClick = (item: folderItem) => {
    dispatch(MetaDataReducerAction.setSelectedSharedFolder(item));
  }; */

  const handleAnClick = (
    event: React.MouseEvent<HTMLElement>,
    item: folderItem
  ) => {
    dispatch(MetaDataReducerAction.resetSkipLimitValue());
    if (isEllipsesOpen !== item?._id) {
      setIsEllipsesOpen(item?._id);
      dispatch(MetaDataReducerAction.setFolderItem(item));
      setSelectedActionFolder(item);
    } else {
      setIsEllipsesOpen(null);
    }
  };

  const handleClick = (boardId: string, folderId: string, index: number) => {
    dispatch(MetaDataReducerAction.resetSkipLimitValue());
    dispatch(SwipeFileReducerAction.setSelectedSidebarBoardId(boardId));
    dispatch(MetaDataReducerAction.resetSwipefileState());
    dispatch(MetaDataReducerAction.setSelectedTabIndex(index));
    dispatch(
      MetaDataSagaActions.filterSharedFolderList({
        selectedFolderId: selectedFolder?._id,
        selectedTabIndex: index,
      })
    );

    dispatch(
      MetaDataSagaActions.fetchFolderListByBoardId({
        selectedFolderId: folderId,
        selectedTabIndex: index,
        boardId: boardId,
      })
    );

    navigate(`${routeConstant.sharedFolders}/${folderId}`, {
      state: {
        type: "sidebarFolder",
      },
    });
  };

  return (
    <>
      <Divider sx={{ borderColor: "#2f4757" }} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "1.5rem 1.5rem 1.8rem 1.8rem",
        }}
      >
        <span>{constants.sharedFolders}</span>
      </div>

      <div style={{ marginLeft: "1rem" }}>
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "17vh",
            "::-webkit-scrollbar": {
              width: "4px",
            },
            marginTop: "-1rem",
          }}
        >
          {sharedFolder?.userSharedFolders?.length > 0 &&
            sharedFolder?.userSharedFolders?.map((item: folderItem) => (
              <div>
                <FolderListItem
                  key={item._id}
                  style={{
                    borderRadius: "4px",
                    background:
                      selectedFolder?._id === item?._id
                        ? "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)"
                        : "transparent",
                  }}
                >
                  <OpenFolder />
                  {drawerOpen && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        onClick={(event) => handleAnClick(event, item)}
                        style={{
                          marginLeft: 10,
                          cursor: "pointer",
                          color: "white",
                          textDecoration: "none",
                        }}
                      >
                        {item.name}
                      </div>
                    </div>
                  )}
                </FolderListItem>
                <Collapse
                  in={isEllipsesOpen === item?._id}
                  timeout="auto"
                  unmountOnExit
                >
                  <span
                    style={{
                      marginLeft: "2.5rem",
                      marginBottom: "0.625rem",
                      display: "block",
                      cursor: "pointer",
                    }}
                  >
                    {selectedFolder?.boards?.length > 0
                      ? "Boards"
                      : "No Boards"}
                  </span>
                  {selectedFolder?.boards?.map(
                    (board: selectFolderBoardItem, index: number) => (
                      <>
                        <List key={board?._id} component="div" disablePadding>
                          <ListItemButton
                            sx={{
                              pl: 4,
                              gap: "0.188rem",
                              paddingLeft: "0.625rem",
                              borderLeft: "1px solid #ffff",
                              marginLeft: "2.625rem",
                              paddingTop: "0.625rem",
                              cursor: "pointer",
                              fontWeight: "bolder",
                              color:
                                selectedTabIndex === index
                                  ? theme.colors.lightBlue
                                  : "white",
                            }}
                            onClick={() =>
                              handleClick(
                                board?._id,
                                selectedFolder?._id,
                                index
                              )
                            }
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: "1.5rem",
                                marginLeft: "0.75rem",
                              }}
                            >
                              <BoardIcon fill="white" />
                            </ListItemIcon>
                            <ListItemText
                              primary={board?.name}
                              sx={{
                                cursor: "pointer",
                              }}
                            />
                          </ListItemButton>
                        </List>
                      </>
                    )
                  )}
                </Collapse>
              </div>
            ))}
        </Box>
      </div>
    </>
  );
};

export default SidebarSharedFolders;
