import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  OutlinedInput,
  Popover,
  Typography,
} from "@mui/material";
import { FontGrayNormal, ModalTitle } from "../modals/style";
import { CustomButton, StyledDivider } from "../../globalStyle";
import { useTheme } from "styled-components";
import { FolderOpenIcon } from "../../assets/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  filterOptionSelector,
  folderAccessMemberListSelector,
  folderItemSelector,
  folderListsSelector,
  folderTeamMemberListSelector,
  isLoadingSelector,
  isRemoveUserModalOpenSelector,
  removeTeamMemberSelector,
  selectedSharedFolderSelector,
} from "../../store/metadata/selector";
import { useEffect, useMemo, useState } from "react";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import {
  FolderAccessMembersType,
  FolderTeamMembersType,
} from "../../store/metadata/types";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { SwipeFileListItems } from "../../store/swipeFile/types";
import AdCard from "../commonComponents/adCard";
import { LibraryButton } from "../commonComponents/brandCards/style";

const AddUserModal = () => {
  const theme = useTheme();
  const { folderId } = useParams();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<string>("");
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedTeamMember, setSelectedTeamMember] = useState("");
  const openAddUserModal = useSelector(isRemoveUserModalOpenSelector);
  const removeId = useSelector(removeTeamMemberSelector);
  const open = Boolean(anchorEl);
  const selectedFolder = useSelector(folderItemSelector);
  const selectedSharedFolder = useSelector(selectedSharedFolderSelector);
  const data = useSelector(folderListsSelector);

  const isShared = data?.userFolders.find(
    (folder: any) => folder.name === selectedFolder?.name
  );

  const folderName = data?.userFolders.find(
    (folder: any) => folder._id === folderId
  );

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setSelectedTeamMember("");
    setAnchorEl(null);
  };

  useEffect(() => {
    if (openAddUserModal?.isOpen) {
      dispatch(
        MetaDataSagaActions.fetchFolderAccessMembersList({
          folderId: openAddUserModal.isUser ? folderId : selectedFolder?._id,
        })
      );
    }
  }, [openAddUserModal?.isOpen, selectedFolder?._id]);

  const fetchMoreFolderTeamMemberList = () => {
    dispatch(
      MetaDataSagaActions.fetchFolderTeamMembersList({
        folderId: openAddUserModal.isUser ? folderId : selectedFolder?._id,
        search: searchText,
      })
    );
  };

  useEffect(() => {
    if (openAddUserModal?.isOpen) {
      dispatch(
        MetaDataReducerAction.setFolderTeamMemberList({
          data: [],
          total: 0,
        })
      );
      dispatch(
        MetaDataSagaActions.fetchFolderTeamMembersList({
          folderId: openAddUserModal.isUser ? folderId : selectedFolder?._id,
          search: searchText,
        })
      );
    }
  }, [openAddUserModal?.isOpen, searchText]);

  const folderAccessMemberList = useSelector(folderAccessMemberListSelector);
  const folderTeamMemberList = useSelector(folderTeamMemberListSelector);
  const isLoading = useSelector(isLoadingSelector);
  const filterOptions = useSelector(filterOptionSelector);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const sendInviteHandler = (value?: string) => {
    const email = value || searchText;
    const isValidEmail = emailRegex.test(email);

    if (!email || !isValidEmail) {
      setIsEmailError(true);
      return;
    }

    setIsEmailError(false);
    dispatch(
      MetaDataSagaActions.sendFolderInvite({
        folderId: openAddUserModal.isUser ? folderId : selectedFolder?._id,
        userEmail: email,
      })
    );
  };

  const shareWithTeamHandler = () => {
    dispatch(
      MetaDataSagaActions.shareFolderWithTeam({
        folderId: selectedFolder?._id,
      })
    );
    dispatch(MetaDataSagaActions.fetchSharedFolderList());
    dispatch(
      MetaDataSagaActions.fetchFolderList({
        selectedFolderId: selectedFolder?._id,
        selectedBoardId: selectedFolder?.boards[0]?._id,
      })
    );
  };

  const RenderTeamMembersListComponent = useMemo(() => {
    const teamMembersItemsLength = folderTeamMemberList?.data?.length ?? 0;
    const hasMoreData = folderTeamMemberList?.total > teamMembersItemsLength;

    return (
      <>
        {folderTeamMemberList?.data?.length > 0 ? (
          <>
            {folderTeamMemberList?.data?.map(
              (item: FolderTeamMembersType, index: number) => (
                <>
                  <Grid item sm={12} xl={6} key={item?._id}>
                    <Box
                      style={{
                        display: "flex",
                        gap: "0.857rem",
                        alignItems: "start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Avatar
                        src={
                          item?.profileImage
                            ? item?.profileImage
                            : "assests/images/user-logo.png"
                        }
                        sx={{
                          width: "2.5rem",
                          height: "2.5rem",
                          marginLeft: "0.5rem",
                        }}
                      />
                      <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          gap: "0.857rem",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Typography
                            fontSize="1rem"
                            fontWeight="500"
                            color={"black"}
                          >
                            {item?.fullName}
                          </Typography>
                          <Typography
                            fontSize="0.875rem"
                            fontWeight="400"
                            color={theme.colors.gray1}
                          >
                            {item?.email}
                          </Typography>
                        </Box>
                        <Box height="40px">
                          <CustomButton
                            style={{
                              fontSize: "14px",
                              fontWeight: 500,
                              color: theme.colors.black2,
                              borderRadius: "20px",
                              border: "1px solid #EAECEE",
                              padding: "10px 20px",
                              height: "100%",
                              display: "flex",
                              gap: "8px",
                            }}
                            onClick={() => {
                              setSelectedTeamMember(item?._id);
                              sendInviteHandler(item?.email);
                            }}
                          >
                            {isLoading && selectedTeamMember === item?._id && (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <CircularProgress size="20px" />
                              </Box>
                            )}
                            Add
                          </CustomButton>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  {folderTeamMemberList?.data?.length - 1 != index && (
                    <StyledDivider />
                  )}
                </>
              )
            )}
            <InfiniteScroll
              dataLength={folderTeamMemberList?.data?.length ?? 0}
              next={() => {}}
              hasMore={hasMoreData}
              loader={<></>}
              style={{ overflow: "hidden" }}
              key={folderAccessMemberList?.data?.length}
            >
              {hasMoreData ?
              <div style={{ display: "flex", justifyContent: "center" }}>
                <LibraryButton
                  sx={{ width: "30%", padding: "0.5rem 0 !important" }}
                  onClick={fetchMoreFolderTeamMemberList}
                  disabled={!hasMoreData}
                  startIcon={
                    !hasMoreData && (
                      <CircularProgress size={20} color="inherit" />
                    )
                  }
                >
                  View More
                </LibraryButton>
              </div>
              :''}
            </InfiniteScroll>
          </>
        ) : (
          <Typography
            fontSize="1rem "
            fontWeight="600"
            textAlign="center"
            color={theme.colors.black2}
          >
            No members found!
          </Typography>
        )}
      </>
    );
  }, [
    folderTeamMemberList?.data,
    folderTeamMemberList?.total,
    isLoading,
    filterOptions?.skip,
  ]);

  const removeMember = (email: string, id: string) => {
    dispatch(
      MetaDataSagaActions.removeTeamMember({
        userEmail: email,
        folderId: openAddUserModal.isUser ? folderId : selectedFolder?._id,
        userId: id,
      })
    );
    return;
  };

  return (
    <Modal
      open={openAddUserModal?.isOpen}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        outline: "none",
      }}
      onClose={() =>
        dispatch(
          MetaDataReducerAction.setIsRemoveUserModalOpen({
            isOpen: false,
            isUser: false,
          })
        )
      }
    >
      <Box
        display="flex"
        sx={{ minWidth: "890px", background: "white", borderRadius: "15px" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="15px"
          padding="1.875rem"
          width="40%"
        >
          <ModalTitle
            fontSize="16px !important"
            display="flex"
            gap="8px"
            alignItems="center"
          >
            <FolderOpenIcon />{" "}
            {folderId === selectedSharedFolder?._id
              ? selectedSharedFolder?.name
              : openAddUserModal?.isUser
              ? folderName?.name
              : selectedFolder?.name}
          </ModalTitle>
          <StyledDivider />
          <Typography fontWeight="600 !important" fontSize="16px !important">
            Access:
          </Typography>
          <Box
            border="1px solid #EAECEE"
            borderRadius="8px"
            display="flex"
            flexDirection="column"
            gap="15px"
            padding="20px"
            paddingBottom={0}
            maxHeight="24.5rem"
            overflow="scroll"
          >
            {folderAccessMemberList?.map(
              (item: FolderAccessMembersType, index: number) => (
                <>
                  <Grid item sm={12} xl={6} key={item?._id}>
                    <Box
                      style={{
                        display: "flex",
                        gap: "0.857rem",
                        alignItems: "start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Avatar
                        src={
                          item?.profileImage
                            ? item?.profileImage
                            : "assests/images/user-logo.png"
                        }
                        sx={{
                          width: "2.5rem",
                          height: "2.5rem",
                          marginLeft: "0.5rem",
                        }}
                      />
                      <Box>
                        <Typography
                          fontSize="1rem"
                          fontWeight="500"
                          color={"black"}
                        >
                          {item?.fullName}
                        </Typography>
                        <Typography
                          fontSize="0.875rem"
                          fontWeight="400"
                          color={theme.colors.gray1}
                        >
                          {item?.email}
                        </Typography>
                      </Box>
                      {item?.canDelete && (
                        <CustomButton
                          onClick={() => removeMember(item?.email, item?._id)}
                          disabled={removeId === item?._id}
                          sx={{
                            padding: "10px 16px",
                            height: "2.25rem",
                            borderRadius: "20px",
                            border: "1px solid #EAECEE",
                            color: "#414042",
                            fontWeight: 500,
                            marginLeft: "auto",
                          }}
                        >
                          {removeId === item?._id && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                marginRight: "6px",
                              }}
                            >
                              <CircularProgress size="12px" />
                            </Box>
                          )}
                          Remove
                        </CustomButton>
                      )}
                    </Box>
                  </Grid>
                  {folderAccessMemberList?.length - 1 != index && (
                    <StyledDivider />
                  )}
                </>
              )
            )}
          </Box>
        </Box>
        <StyledDivider orientation="vertical" variant="middle" flexItem />
        <Box
          display="flex"
          flexDirection="column"
          gap="15px"
          padding="1.875rem"
          width="60%"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" flexDirection="column" width="50%">
              <ModalTitle fontSize="16px !important">Add Users</ModalTitle>
              <FontGrayNormal>
                Search by name and email or invite new user.
              </FontGrayNormal>
            </Box>
            <Box>
              {isShared?.shareWithTeam ? (
                <>
                  <CustomButton
                    style={{
                      fontSize: "16px",
                      color: theme.colors.white,
                      cursor: "default",
                      borderRadius: "27px",
                      border: "1px solid #EAECEE",
                      background:
                        "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)",
                      padding: "14px 20px",
                      height: "38px",
                    }}
                    aria-owns={open ? "mouse-over-popover" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    Share to Team
                  </CustomButton>
                  <Popover
                    id="mouse-over-popover"
                    sx={{ pointerEvents: "none", ml: 2.5 }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Typography sx={{ p: 1.2 }}>Already shared</Typography>
                  </Popover>
                </>
              ) : (
                <>
                  <CustomButton
                    style={{
                      fontSize: "16px",
                      color: theme.colors.white,
                      borderRadius: "27px",
                      border: "1px solid #EAECEE",
                      background:
                        "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)",
                      padding: "14px 20px",
                      height: "38px",
                    }}
                    onClick={shareWithTeamHandler}
                  >
                    Share to Team
                  </CustomButton>
                </>
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap="10px"
            height="46px"
            width="100%"
          >
            <OutlinedInput
              error={isEmailError}
              placeholder="zuck@facebook.com"
              sx={{
                width: "60%",
                borderRadius: "20px !important",
                height: "100%",
                border: "1px solid #EAECEE !important",
                background: "#F6F7F8 !important",
                input: {
                  "&::placeholder": {
                    border: 0,
                    color: theme.colors.gray1,
                    fontWeight: 500,
                    opacity: 0.5,
                    outline: "none",
                  },
                },
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <CustomButton
              style={{
                fontSize: "16px",
                color: theme.colors.gray1,
                borderRadius: "27px",
                border: "1px solid #EAECEE",
                padding: "14px 20px",
                height: "100%",
              }}
              disabled={!searchText}
              onClick={() => sendInviteHandler()}
            >
              Send Invitation
            </CustomButton>
          </Box>
          {isEmailError && (
            <Alert severity="error" onClose={() => setIsEmailError(false)}>
              Please enter a valid email.
            </Alert>
          )}

          <Box display="flex" flexDirection="column" gap="10px">
            <Typography fontWeight="600 !important" fontSize="16px !important">
              Search Users :
            </Typography>
            <Box
              border="1px solid #EAECEE"
              borderRadius="8px"
              display="flex"
              flexDirection="column"
              gap="15px"
              padding="20px"
              paddingBottom={0}
              maxHeight="19.5rem"
              overflow="scroll"
            >
              <>{RenderTeamMembersListComponent}</>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddUserModal;
